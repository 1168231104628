<!-- 托管组织排名 -->
<template>
  <LandCard title="放款机构排行" :isLeftMore="false">
    <template>
      <el-select
        class="selsectbox"
        v-model="yearValue"
        placeholder="请选择"
        size="small"
        @change="handleValueChange"
      >
        <el-option
          v-for="item in yearOptions"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="pie-area">
        <div class="left-area">
          <chart :options="fkOptions"></chart>
        </div>
        <div>
          <chart :options="xsOptions"></chart>
        </div>
      </div>
    </template>
  </LandCard>
</template>

<script>
import LandCard from "@/components/Card/landTrustCard.vue";
import RankCard from "@/components/echart/CategoryRankChart.vue";
import chart from "@/components/echart/echart";
import { getFKZDJGInfo, getXSZDCPInfo } from "@/api/financialService.js";
export default {
  components: {
    LandCard,
    RankCard,
    chart,
  },
  data() {
    return {
      yearValue: 2022,
      yearOptions: [
        {
          name: "2020年",
          value: 2020,
        },
        {
          name: "2021年",
          value: 2021,
        },
        {
          name: "2022年",
          value: 2022,
        },
        {
          name: "2023年",
          value: 2023,
        },
      ],
      fkOptions: null,
      xsOptions: null,
      fkData: [],
      xsData: [],
    };
  },

  mounted() {
    this.getFirstData();
    this.getSecondData();
  },
  methods: {
    //放款最多
    async getFirstData() {
      let params = {
        year: this.yearValue,
      };
      let res = await getFKZDJGInfo(params);

      if (!res || res.code != 0) {
        return;
      }
      this.fkData = res.data;
      this.initFK(this.fkData);
    },
    //销售最多
    async getSecondData() {
      let params = {
        year: this.yearValue,
      };
      let res = await getXSZDCPInfo(params);
      if (!res || res.code != 0) {
        return;
      }
      this.xsData = res.data;
      this.initXS(this.xsData);
    },
    handleValueChange(val) {
      this.getFirstData();
      this.getSecondData();
    },
    initFK(obj) {
      const dataList = obj;
      this.fkOptions = {
        xAxis: {
          show: false,
        },
        yAxis: {
          show: false,
        },
        tooltip: {
          trigger: "item",
          showContent: true,
          backgroundColor: "rgba(8,36,68,.7)",
          color: "#fff",
          textStyle: {
            color: "#fff",
          },
          //valueFormatter: (value) => value + " " + "万元",
          formatter:function(params){
            // var html=params.seriesName+"<br>";
            // html+=params.marker+"  "+params.name+"  "+params.value+"万元"+"  "+params.percent+"%";
            var html=params.marker+"  "+"放款机构"+"："+params.name+"<br>";
            html+=params.marker+"  "+"放款金额"+"："+params.value+"万元"+"<br>"+params.marker+"  "+"所占比例"+"："+params.percent+"%";
            return html;
          }
        },
        title: {
          // show: true,
          // top: "43%",
          left: "4%",
          // textAlign: "center",
          text: "放款最多机构",
          textStyle: {
            color: "#333333",
            fontSize: 14,
            fontWeight: "bold",
            lineHeight: 30,
          },
        },
        series: [
          {
            name: "放款最多机构",
            type: "pie",
            // radius: [78, 102],
            radius: "50%",
            selectedMode: "single",
            data: dataList,
            label: {
              normal: {
                show: true,
                formatter: (params) => {
                  //return params.name + " " + params.percent + "%";
                  return params.name
                },
                textStyle: {
                  fontSize: 14,
                  color: "inherit",
                },
              },
            },
          },
        ],
      };
    },
    initXS(obj) {
      const dataList = obj;
      this.xsOptions = {
        xAxis: {
          show: false,
        },
        yAxis: {
          show: false,
        },
        tooltip: {
          trigger: "item",
          showContent: true,
          backgroundColor: "rgba(8,36,68,.7)",
          color: "#fff",
          textStyle: {
            color: "#fff",
          },
         // valueFormatter: (value) => value + " " + "万元",
         formatter:function(params){
            // var html=params.seriesName+"<br>";
            // html+=params.marker+"  "+params.name+"  "+params.value+"万元"+"  "+params.percent+"%";
            var html=params.marker+"  "+"销售产品"+"："+params.name+"<br>";
            html+=params.marker+"  "+"销售金额"+"："+params.value+"万元"+"<br>"+params.marker+"  "+"所占比例"+"："+params.percent+"%";
            return html;
          }
        },
        title: {
          left: "4%",
          text: "销售最多产品",
          textStyle: {
            color: "#333333",
            fontSize: 14,
            fontWeight: "bold",
            lineHeight: 30,
          },
        },
        series: [
          {
            name: "销售最多产品",
            type: "pie",
            // radius: [78, 102],
            radius: "50%",
            selectedMode: "single",
            data: dataList,
            label: {
              normal: {
                show: true,
                formatter: (params) => {
                  //return params.name + " " + params.percent + "%";
                  return params.name
                },
                textStyle: {
                  fontSize: 14,
                  color: "inherit",
                },
              },
            },
          },
        ],
      };
    },
  },
};
</script>

<style lang="less" scoped>
.rankCardStyle {
  width: 100%;
  height: calc(100% - 100px);
}
.selsectbox {
  position: relative;
  top: -10%;
  left: 75%;

  width: 130px;

  /deep/ .el-input__inner {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
  }
}

.pie-area {
  display: flex;
  height: calc(100% - 100px);

  > div {
    flex: 1;
  }

  .left-area {
    border-right: dotted 1px #ccc;
  }
}
</style>
