//金融服务相关接口
import service from "../request";

const baseUrl = window.globalUrl.HOME_API;

//1、成果展示
export const getCGZSInfo = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/loanDataStatistics/dataStatistics`,
    params: params,
  });
};

//2、放款动态
export const getLoanDynamicInfo = (params) => {
    return service({
      method: "get",
      url: `${baseUrl}/trade-website/loanDataStatistics/getLoanList`,
      params: params,
    });
  };

//3、放款机构排行
//放款最多机构
export const getFKZDJGInfo = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/loanDataStatistics/mostLoanBank`,
    params: params,
  });
};
//销售最多产品
export const getXSZDCPInfo = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/loanDataStatistics/mostLoanProduct`,
    params: params,
  });
};

//贷款区域排名
export const getDKQYPMInfo = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/loanDataStatistics/mostLoanArea`,
    params: params,
  });
};
